@font-face {
  font-family: 'Geist';
  src: url('./fonts/Geist.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.geist{
  font-family: 'Geist';
}

html {
  font-family: 'Geist';
}